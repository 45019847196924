<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-03-31 14:04:27
 * @LastEditTime: 2023-06-30 18:51
 * @Descripttion: 【教学-课件资源】详情
-->
<style lang="scss" scoped>
.courseware {
    &-detail {
        @include innerPage;
        @include pageHead(-42px);
    }

    &-inner {
        width: 100%;
        height: calc(100% - 196px);
        box-sizing: border-box;
        padding-left: 12px;
        padding-right: 34px;
        margin-top: 34px;
        overflow: hidden;
        overflow-y: auto;
    }

    &-info {
        &--box {
            width: 100%;
            background: #f6f7fc;
            border-radius: 10px;
            box-sizing: border-box;
            padding: 34px 40px 48px 48px;

            h3 {
                line-height: 38px;
                color: #232323;
            }

            .desc {
                width: calc(100% - 30px);
                margin: 8px 0;
                line-height: 26px;
                color: #4a4a4a;
            }

            .other-info {
                height: 24px;
                font-size: 16px;
                color: #696969;
                margin-top: 8px;
                @include flexBox;

                .time {
                    margin: 0 24px 0 10px;
                }

                .iconfont {
                    font-size: 20px;
                }
            }

            .user-info {
                margin-top: 16px;
                @include flexBox(space-between);

                .uploader {
                    @include flexBox;

                    .el-avatar {
                        border-radius: 50%;
                        border: solid 1px #ececee;
                        margin-right: 8px;
                    }

                    .pf_medium {
                        font-size: 16px;
                        color: #404040;
                    }
                }
            }
        }

        &--medium {
            width: 74%;
            margin: 30px auto 0;

            img {
                width: 100%;
                height: auto;
                border-radius: 30px;
            }
        }
    }
}

.audit {
    &-box {
        box-sizing: border-box;
        padding: 50px 0 0 0;
        margin-top: 50px;
        border-top: 1px solid #e1e2eb;

    }

    &-result {
        width: calc(100% - 20px);
        margin: 30px auto 0;

        &--inner {
            width: 100%;
            margin-bottom: 50px;
            background: #f6f7fc;
            border-radius: 10px;
            box-sizing: border-box;
            padding: 20px 28px;

            p {
                width: 760px;
                min-height: 46px;
                box-sizing: border-box;
                padding: 11px 0;
                font-size: 16px;
                color: #484848;
                @include flexBox(flex-start, flex-start);

                span {
                    display: inline-block;
                    line-height: 24px;

                    &.label {
                        width: 84px;
                        padding-right: 20px;
                        text-align: right;
                        box-sizing: border-box;
                    }

                    &.failed {
                        color: #aeaeae;
                    }

                    &.primary {
                        color: #f8c436;
                    }

                    &.success {
                        color: #3bc8af;
                    }
                }
            }
        }
    }

    &-form {
        width: 760px;
        box-sizing: border-box;
        padding-top: 30px;
        padding-left: 20px;

        .buttons {
            margin-top: 60px;
        }
    }
}
</style>

<template>
    <section class="courseware-detail">
        <div class="page-head">
            <img class="bitmap" src="@assets/images/bitmap-head.png" alt="">
            <div class="head-wrapper">
                <breadcrumb />
                <el-button type="custom_primary" size="medium" @click="$router.go(-1)">
                    返回
                </el-button>
            </div>
        </div>
        <div class="courseware-inner">
            <div class="courseware-info--box">
                <h3 class="bold">{{ detailData[`${keyPrefix}cou_title`] || '-' }}</h3>
                <p class="desc light">
                    {{ detailData[`${keyPrefix}cou_remark`] }}
                </p>
                <p class="other-info pf_medium">
                    <span class="iconfont">&#xe61a;</span>
                    <span class="time">
                        {{ detailData.create_time | formatTime('YYYY/MM/DD') }}</span>
                    <span>
                        {{ detailData[`${keyPrefix}cou_grade_name`] || detailData[`${keyPrefix}cou_grade`] }}
                    </span>
                </p>
                <div class="user-info">
                    <p class="uploader">
                        <el-avatar :size="62" :src="formatfile(detailData.teuse_image)">
                            <img src="@assets/images/empty_avatar.png" />
                        </el-avatar>
                        <span class="pf_medium">{{ detailData.teuser_name }}</span>
                    </p>
                    <el-button type="custom_primary" size="small" @click="downloadFile" :disabled="btnLoad" v-loading="btnLoad">
                        下载
                    </el-button>
                </div>
            </div>
            <div class="courseware-info--medium" v-if="mediumType(detailData[`${keyPrefix}cou_type`])">
                <template v-if="mediumType(detailData[`${keyPrefix}cou_type`]) == 'image'">
                    <img :src="formatfile(detailData[`${keyPrefix}cou_url`])" alt="">
                </template>
                <template v-if="mediumType(detailData[`${keyPrefix}cou_type`]) == 'video'">
                    <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true"
                        :options="playerOptions" />
                </template>
            </div>
            <div class="audit-box" v-if="$route.params.type == 'my' && detailData.tecou_sharestatus > 10">
                <div class="page-title">
                    <h3>审核信息</h3>
                </div>
                <div class="audit-result">
                    <div class="audit-result--inner">
                        <p>
                            <span class="label">审核</span>
                            <span class="value"
                                :class="{ failed: detailData.tecou_sharestatus == 40, success: detailData.tecou_sharestatus == 30, primary: detailData.tecou_sharestatus == 20 }">
                                {{ detailData.tecou_sharestatus == 40 && '驳回' || detailData.tecou_sharestatus == 30 && '已通过'
                                    || '待审核' }}
                            </span>
                        </p>
                        <template v-if="detailData.tecou_sharestatus > 20">
                            <p>
                                <span class="label">审核意见</span>
                                <span class="value">{{ detailData.tecou_reason || '-' }}</span>
                            </p>
                            <p>
                                <span class="label">审核人</span>
                                <span class="value">{{ detailData.tecou_exa_name || '-' }}</span>
                            </p>
                            <p>
                                <span class="label">审核时间</span>
                                <span class="value">{{ detailData.tecou_exa_time | formatTime('YYYY/MM/DD HH:mm') }}</span>
                            </p>
                        </template>
                    </div>
                    <el-button type="custom_primary" size="small" v-if="detailData.tecou_sharestatus == 40"
                        @click.stop="shareFile">
                        重新共享
                    </el-button>
                </div>
            </div>
        </div>

    </section>
</template>

<script>
import { $coursewareDetail, $shareCourseware } from "@api/teaching"
import { $downloadFile } from "@api/common"
import { formatFile, formatFileType } from "@utils"
export default {
    name: 'teaching_coursewareDetail',
    computed: {
        fileType() {
            return function (val) {
                return formatFileType(val);
            }
        },
        formatfile() {
            return function (url) {
                return formatFile(url)
            }
        },
        mediumType() {
            return function (val) {
                const videoType = ["mp4", "avi", "wma", "rmvb", "rm", "flash", "mid", "3gp"];
                const imgType = ["jpg", "jpeg", "png", "svg", "gif", "tif", "bmp", "webp"];
                if (videoType.indexOf(val) == -1 && imgType.indexOf(val) == -1) return false;
                else if (videoType.indexOf(val) != -1) return 'video';
                else if (imgType.indexOf(val) != -1) return 'image'
            }
        }
    },
    data() {
        return {
            detailData: {},
            btnLoad: false,
            keyPrefix: 'te',
            /* 视频播放器配置 */
            playerOptions: {
                playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
                autoplay: false, // 如果true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 导致视频一结束就重新开始。
                preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: 'zh-CN',
                aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [],
                poster: '', // 视频封面
                notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controls: true,
                controlBar: {
                    timeDivider: true,
                    durationDisplay: true,
                    remainingTimeDisplay: false,
                    fullscreenToggle: true // 全屏按钮
                }
            },
        }
    },
    created() {
        this.getDetail();
    },
    methods: {
        /** 获取课件资源详情 */
        async getDetail() {
            let { type, id } = this.$route.params;
            if (type == 'my') type = '';
            switch (type) {
                case 'school':
                    this.keyPrefix = 'sc'
                    break;
                case 'platform':
                    this.keyPrefix = 'pl'
                    break;
                default:
                    type = ''
                    this.keyPrefix = 'te'
                    break;
            }
            let { data } = await $coursewareDetail(type, id);
            this.detailData = data;
            if (this.mediumType(data[`${this.keyPrefix}cou_type`]) == 'video') {
                this.playerOptions = {
                    ...this.playerOptions,
                    sources: [{
                        type: 'video/mp4',
                        src: this.formatfile(data[`${this.keyPrefix}cou_url`])
                    }]
                };
            }
            this.$forceUpdate();
        },
        /** 重新共享 */
        shareFile() {
            let id = this.$route.params.id;
            this.$msgbox({
                title: "提示",
                message: "确认将此课件资源重新共享至学校？",
                type: "info",
                showClose: false,
                showCancelButton: true,
                cancelButtonClass: "el-button--custom_info",
                confirmButtonClass: "el-button--custom_primary",
                confirmButtonText: "确定",
                cancelButtonText: "取消",
            }).then(async () => {
                let res = await $shareCourseware(id);
                if (res) {
                    this.$message({
                        type: "success",
                        duration: 1500,
                        message: "申请已提交，等待学校审核",
                    });
                    this.getDetail();
                }
            }).catch(() => {
                this.$message({
                    type: "info",
                    duration: 1500,
                    message: "操作已取消",
                });
            });
        },
        /** 文件下载 */
        async downloadFile() {
            this.btnLoad = true;
            let url = this.detailData.tecou_url || this.detailData.sccou_url || this.detailData.plcou_url;
            let { data } = await $downloadFile(url);
            this.btnLoad = false;
            if (data.url) {
                this.$fileDownload(data.url)
            };
        }
    },
}
</script>